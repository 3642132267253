import DownloadButton from '../../views/components/DownloadButton.vue'
import data from '../../views/JS/OrderList.json'
import config from '../../../config';

export default {
    data() {

        return {
            id: this.$route.params.id,
            TransportTypeList: [
                {
                    id: 1,
                    name: 'air'
                },
                {
                    id: 2,
                    name: 'sea'
                },

            ],
            selected: JSON.parse(this.$route.params.selected),
            loaderCompany:'KTL',
            index: this.$route.params.index,
            selectedTransportType: 'sea',
            name: 'sea',
            selectedTransportWarehouse: '',
            data: data,
            TransportCount: '',
            selectCountry: {
                name: '',
                id: ''
            },
            CountryList: [
                {
                    name: 'Has-Ankara',
                    id: 1
                },
                {
                    name: 'Meh-Ankara',
                    id: 2
                },
                {
                    name: 'İstanbul',
                    id: 3
                },
            ]
        }
    },

    watch: {

    },
    methods: {



        backButton() {
            this.$router.push({
                name: 'order',
                params: {
                    id: this.id,

                }
            })
        },
        baseChangeLocation(item) {
            this.selectCountry.name = item.name
            console.log("değişti", this.selectCountry.name)
        },
        transformProduct() {

            const Remaining = this.selected.Quantity - this.TransportCount
            console.log("Quantity", this.selected.Quantity)
            console.log("Remaining", Remaining)
            console.log("BoxNumber", this.selected.BoxID)
            console.log("Transport", Number(this.TransportCount))
            console.log("ShipVia", this.selectedTransportType)
            console.log("Branch", this.selectCountry.name)
            console.log("Remaining", this.selected)

            if(this.TransportCount> Number(this.selected.Remaining)){
                alert("Number of transfers cannot be greater than the amount")

            }
            else if(this.TransportCount===''){
                alert("Please Select Transport Count")

            }
            else if(this.selectCountry.name===''){
                alert("Please Select Transport Branch")

            }
            else{

                const date = (this.$moment(new Date()).format("DD-MM-YYYY HH:ss"))
                const formData = new FormData();
                formData.append("IndexID", this.selected.IndexID);
                formData.append("Transport", Number(this.TransportCount));
                formData.append("Remaining", Number(Remaining));
                formData.append("ShipVia", this.selectedTransportType);
                formData.append("Branch", this.selectCountry.name);
                formData.append("BoxID", this.selected.BoxID);
                formData.append("ShipedDate", date);
                formData.append("Weight", this.selected.weight);
                formData.append("BoxContent", this.selected.boxContent);
                formData.append("ShipNumber", this.selected.shipNumber);
                formData.append("LoaderCompany", this.loaderCompany);
                this.$http.post(`${config.API_BASE}/api/v1/order`, formData, this.config).then((response) => {


                    setTimeout(() => {
                        this.openModal = false
                        this.$vs.notify({
                            color: 'success',
                            title: 'Success',
                            text: 'Transfer Success.'
                        })
                        this.$router.push({
                            name: 'order',
                            params: {
                                id: this.id,

                            }
                        })
                    }, 1000);
                });

            }



        }
    },
    mounted() {
        console.log("bu geldi", this.selected)
    },

    components: {
        DownloadButton,
    },

}

