<template>
  <div >

    <div ref="myPdfComponent" :style="{top:`68px`,right:`28px` }" class="DownloadBtnWrapper">
      <export-excel
          :data="this.downloadData"
          :name="this.downloadDataName">
        <div class="button2"><div class="icon">
          <div class="arrow"></div>
          <svg class="line" viewBox="0 0 24 24"></svg>
        </div>Excel</div>
      </export-excel>
    <!--  <b-dropdown style="font-size: 12px" id="dropdown-offset" offset="25" :text="$t('download')" class="DownloadBtn">

        <b-dropdown-item>
          <div class="downloadButtonNew" >
            <div  style="margin-right: 3px;  "><img src="@/assets/images/downloadIcons/xls.png" width="10"></div>



          </div>

        </b-dropdown-item>
&lt;!&ndash;        <b-dropdown-item>
          <div :class="{'downloadButtonNewPassive' : this.activePDF === false}" class="downloadButtonNew ">
            <div><img src="@/assets/images/downloadIcons/csv.png" width="14" height="16"></div>
            <div @click="exportToCsv()">{{ $t('download') }} Csv</div>
          </div>

        </b-dropdown-item>&ndash;&gt;
      </b-dropdown>
-->
    </div>

  </div>
</template>
<script>



export default {

  data() {
    return {
      downloadModal: false,
      activePDF: false,
      activeExcel: true,
      activeCsv: false,


    }
  },
  props: ['downloadDataName', 'downloadData','top','right'],
  components: {},
  mounted() {

  },
  destroyed() {
  },
  computed: {},
  watch: {},
  methods: {
    openDownloadModal() {
      this.downloadModal = !this.downloadModal
    },
    exportToCsv() {
      const rows = this.downloadData
      let filename = this.downloadDataName
      const processRow = function (row) {
        let finalVal = '';
        for (let j = 0; j < row.length; j++) {
          let innerValue = row[j] === null ? '' : row[j].toString();
          if (row[j] instanceof Date) {
            innerValue = row[j].toLocaleString();
          }

          let result = innerValue.replace(/"/g, '""');
          if (result.search(/("|,|\n)/g) >= 0)
            result = '"' + result + '"';
          if (j > 0)
            finalVal += ',';
          finalVal += result;
        }
        return finalVal + '\n';
      };

      let csvFile = '';
      for (let i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
      }

      const blob = new Blob([csvFile], {type: 'text/csv;charset=utf-8;'});
      if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    generateReport () {
      this.$refs.html2Pdf.generatePdf()
    }
  }
}
</script>
<style lang="scss">
.dropdown-menu.show {
  left: -45px !important;

}

.DownloadBtnWrapper {
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 18px;
  bottom: 3px;
  margin-right: 11em;
  font-size: 12px;
  line-height: 22px;
  border-radius: 4px;
  border: solid 1px #f1f1f1;
  background-color: #ffffff;
  cursor: pointer;
  width: 100px;
  height: 40px;
  justify-content: center;
  overflow: hidden;


  .btn {
    padding: 3px 5px;
    font-size: 12px !important;
  }

  .downloadButtonNew {
    width: 100%;
    display: flex !important;
    font-size: 12px;
    line-height: 12px;
    margin: 5px auto;
    padding: 5px;
    border-radius: 4px;
    border: solid 1px #333333;
    background-color: #f5f5f5;
    cursor: pointer;

    justify-content: space-evenly;
  }

  .downloadButtonNewPassive {
    width: 100%;
    display: flex !important;
    font-size: 12px;
    line-height: 12px;
    margin: 5px auto;
    padding: 5px;
    border-radius: 4px;
    border: solid 1px #333333;
    background-color: #f5f5f5;
    cursor: pointer;
    justify-content: space-evenly;
  }

  .downloadButtonNewPassive:hover {
    background-color: #e5e5e5 !important;
    color: grey !important;
    cursor: default;
  }

  .downloadButtonNew:hover {
    background-color: #7c7c7c;
    color: white;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-menu.show li {
    border-bottom: none;
    padding: 0 10px;
  }

  .dropdown-item:hover, .dropdown-item:focus {
    background: none;
  }

  .dropdown-item {
    display: flex;
    padding: 0;
  }

  .downloadModal {
    width: 180px;
    height: 170px;
    margin: 38px 2px 1px;
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.14);
    background-color: #ffffff;
    position: absolute;
    z-index: 9999;
    top: 10px;
    right: -3px;
    display: flex;
    justify-content: center;

    .buttonsWrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;

      .downloadTitle {
        font-weight: bold;
        color: black;
        font-size: 14px;
        margin-bottom: 10px;
      }


      .cancelButton {
        margin: auto;
        width: 77px;
        line-height: 25px;
        border-radius: 7px;
        box-shadow: 0 5px 10px 0 #dcdcdc;
        border: solid 1px #333333;
        background-color: #ffffff;
        color: #333333;
        margin-top: 10px;
        cursor: pointer;
      }
    }
  }

}
.button2 {
  --background: #3d5a80;
  border-radius: 4px;
  --background-hover: #1E2235;
  --text: #fff;
  --icon: #fff;
  --particle: #fff;
  display: flex;
  outline: none;
  cursor: pointer;
  border: 0;
  min-width: 113px;
  padding: 8px 20px 8px 12px;
  border-radius: 11px;
  line-height: 24px;
  font-family: inherit;
  font-weight: 600;
  font-size: 14px;
  color: var(--text);
  background: var(--b, var(--background));
  transition: transform .3s, background .4s;
  transform: scale(var(--scale, 1)) translateZ(0);
  &:active {
    --scale: .95;
  }
  &:hover {
    --b: var(--background-hover);
  }
  .icon {
    --arrow-y: 0;
    --arrow-rotate: 135;
    --arrow-top: 10px;
    width: 24px;
    height: 24px;
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin-right: 8px;
    pointer-events: none;
    .dot {
      border-radius: 50%;
      background: #fff;
      background: var(--particle);
      position: absolute;
      left: 0;
      top: 0;
      width: 4px;
      height: 4px;
    }
    .arrow,
    .line {
      position: absolute;
      z-index: 1;
    }
    .arrow {
      left: 11px;
      top: 4px;
      width: 2px;
      height: 12px;
      border-radius: 1px;
      background: var(--icon);
      transform: translateY(calc(var(--arrow-y) * 1px)) translateZ(0);
      &:before,
      &:after {
        content: '';
        width: 2px;
        height: 7px;
        position: absolute;
        left: 0;
        top: var(--arrow-top);
        border-radius: 1px;
        background: inherit;
        transform-origin: 1px 1px;
        transform: rotate(var(--r, calc(var(--arrow-rotate) * 1deg)));
      }
      &:after {
        --r: calc(var(--arrow-rotate) * -1deg);
      }
    }
    .line {
      width: 24px;
      height: 24px;
      display: block;
      left: 0;
      top: 7px;
      fill: none;
      stroke: var(--icon);
      stroke-width: 2;
      stroke-linejoin: round;
      stroke-linecap: round;
    }
  }
  &.upload {
    .icon {
      --arrow-rotate: 45;
      --arrow-top: 0;
    }
  }
}
.DownloadBtn {
  width: auto;
  line-height: 29px;
  border-radius: 4px;

  border: solid 1px #c6c5c5;
  background-color: #ffffff;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  padding: 3px 20px 3px 5px;
  font-size: 12px !important;

}

@media screen and (max-width: 500px) {

  .DownloadBtn {
    line-height: 30px;
    width: 70px !important;
    font-size: 7px !important;
    margin-top: 0 !important;
  }


  .DownloadBtnWrapper {
    display: -webkit-box;
    display: none;
    -webkit-box-pack: end;
    justify-content: flex-end;
    right: 2.7%;
    margin-top: 0px;
    margin-right: -10px;

  }
}

</style>
